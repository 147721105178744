import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "./views/Home/Home";
import Redeem from "./views/Redeem/redeem";

const App: React.FC = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "redeem", element: <Redeem /> }
  ]);

  return routes;
};

export default App;
