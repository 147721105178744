import { useEffect, useState } from "react";
import TestNfts from "../config/abi/TestNfts.json";
import { getContract } from "../utils/web3";

export const useTokenId = (address: string) => {
  const [tokenId, setTokenId] = useState<String>();

  useEffect(() => {
    async function fetchTotalSupply() {
      const nftContract = getContract(
        TestNfts,
        process.env.REACT_APP_CONTRACT_ADDRESS || ""
      );

      if (address !== "") {
        const supply = await nftContract.methods.balanceOf(address).call();
        let token_idxs: number[] = Array.from(Array(Number(supply)).keys());

        for await (const idx of token_idxs) {
          let token_id = await nftContract.methods
            .tokenOfOwnerByIndex(address, idx)
            .call();
          setTokenId(token_id);
        }
      }
    }

    fetchTotalSupply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return tokenId;
};
