import tShirt from "../assets/images/redeem/gift/t-shirt.png";
import bottle from "../assets/images/redeem/gift/bottle.png";
import cap from "../assets/images/redeem/gift/cap.png";
import opener from "../assets/images/redeem/gift/opener.png";
import checkIn from "../assets/images/redeem/event/check-in.png";
import bonus1 from "../assets/images/redeem/event/bonus1.png";
import bonus2 from "../assets/images/redeem/event/bonus2.png";

const claimItems = [
  {
    id: 1,
    name: "redeem_600",
    imgs: tShirt,
    desc: "T-Shirt",
    points: "600",
    disabled: false,
    selected: false,
    isAis: false,
    loading: false
  },
  {
    id: 2,
    name: "redeem_600",
    imgs: bottle,
    desc: "Bottle",
    points: "600",
    disabled: false,
    selected: false,
    isAis: false,
    loading: false
  },
  {
    id: 3,
    name: "redeem_400",
    imgs: cap,
    desc: "Cap",
    points: "400",
    disabled: false,
    selected: false,
    isAis: false,
    loading: false
  },
  {
    id: 4,
    name: "redeem_200",
    imgs: opener,
    desc: "Opener",
    points: "200",
    disabled: false,
    selected: false,
    isAis: false,
    loading: false
  }
];

const eventBonus = [
  {
    id: 1,
    name: "check_in",
    desc: "Checked In",
    points: "200",
    imgs: checkIn,
    disabled: false,
    selected: false,
    loading: false
  },
  {
    id: 2,
    name: "claim_bonus_1",
    desc: "Bonus 1",
    points: "200",
    imgs: bonus1,
    disabled: false,
    selected: false,
    loading: false
  },
  {
    id: 3,
    name: "claim_bonus_2",
    desc: "Bonus 2",
    points: "200",
    imgs: bonus2,
    disabled: false,
    selected: false,
    loading: false
  }
];

export const STATUS_CODE = {
  "EXPO:0001": {
    MSG: "REGISTER SUCCESS"
  },
  "EXPO:0002": {
    MSG: "ACTION SUCCESS"
  },
  "EXPO:0003": {
    MSG: "Request Success"
  },
  "EXPO:0100": {
    MSG: "INVALID REQUEST"
  },
  "EXPO:0101": {
    MSG: "MONGODB ERROR"
  },
  "EXPO:0102": {
    MSG: "REGISTER FAIL"
  },
  "EXPO:0103": {
    MSG: "WHITELIST NOT FOUND"
  },
  "EXPO:0104": {
    MSG: "CANT GET SECRET DATA"
  },
  "EXPO:0105": {
    MSG: "SECRET NOT FOUND"
  },
  "EXPO:0106": {
    MSG: "Invalid Seed Phrase"
  },
  "EXPO:0107": {
    MSG: "EXIST REGISTER WALLET"
  },
  "EXPO:0108": {
    MSG: "WHITELIST USED"
  },
  "EXPO:0109": {
    MSG: "TRANSACTION FAIL"
  },
  "EXPO:0110": {
    MSG: "TOKEN NOT FOUND"
  },
  "EXPO:0111": {
    MSG: "CHAIN NOT SUPPORT"
  },
  "EXPO:0112": {
    MSG: "MINT_FAIL"
  },
  "EXPO:0113": {
    MSG: "METADATA BASE URI GET FAIL"
  },
  "EXPO:0114": {
    MSG: "CREATE METADATA FAIL"
  },
  "EXPO:0115": {
    MSG: "CREATE METADATA FAIL WITH API"
  },
  "EXPO:0116": {
    MSG: "MAPPING METADATA ERROR"
  },
  "EXPO:0117": {
    MSG: "DECRYPT FAIL"
  },
  "EXPO:0118": {
    MSG: "ENCODE FAIL"
  },
  "EXPO:0119": {
    MSG: "INIT CRYPTO FAIL"
  },
  "EXPO:0120": {
    MSG: "INVALID UUID"
  },
  "EXPO:0121": {
    MSG: "Invalid Seed Phrase"
  },
  "EXPO:0122": {
    MSG: "Invalid front mnemonic or uuid"
  },
  "EXPO:0123": {
    MSG: "Invalid admin id"
  },
  "EXPO:0124": {
    MSG: "Invalid schema"
  }
};

export const constantsRedeem = {
  claimItems,
  eventBonus,
  STATUS_CODE
};
