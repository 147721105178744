import axios from "axios";

const useNft = (ticketId: string, whitelistId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/admin/whitelist/use/ticketId`,
        {
          ticketId: ticketId,
          whitelistId: whitelistId
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const checkTicketId = (ticketId: string, whitelistId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/admin/whitelist/check/ticketId`,
        {
          ticketId: ticketId,
          whitelistId: whitelistId
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const register = (frontMnmonic: string, backMnemonic: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/registerWallet`, {
        frontMnmonic: frontMnmonic,
        backMnemonic: backMnemonic
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const verify = (address: string, frontMnemonic: string, uuid: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/verify-address`, {
        address: address,
        frontMnemonic: frontMnemonic,
        uuid: uuid
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const adminAction = (
  frontMnmonic: string,
  backMnemonic: string,
  action: string,
  tokenId: string,
  adminId: string
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/action`, {
        frontMnmonic: frontMnmonic,
        backMnemonic: backMnemonic,
        action: action,
        tokenId: tokenId,
        projectId: process.env.REACT_APP_PROJECT_ID,
        adminId: adminId
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getMetaData = (tokenId: Number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/${tokenId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const adminService = {
  useNft,
  checkTicketId,
  register,
  adminAction,
  getMetaData,
  verify
};
