import React from "react";

export default function dialogConfirm({
  setDialogOn,
  item,
  confirm,
  cancel
}: {
  setDialogOn: any;
  item: any;
  confirm: any;
  cancel: any;
}) {
  const onConfirm = () => {
    confirm(true);
    cancel(false);
    setDialogOn(false);
  };

  const onCancle = () => {
    cancel(true);
    confirm(false);
    setDialogOn(false);
  };

  return (
    <div className="fixed inset-0 z-50 bg-blue-400 bg-opacity-50">
      <div className="flex items-center justify-center h-screen">
        <div className="flex-col px-10 py-6 mx-3 bg-blue-900 border-2 shadow-xl rounded-xl w-96">
          <p className="mb-5 text-lg font-bold text-white font-montserrat">
            Confirm Redeem
          </p>
          <div className="text-center">
            <div className="flex items-center justify-center ">
              <img src={item[1]} alt="gift" width={120} />
            </div>

            <p className="flex justify-center my-5 font-semibold text-white font-montserrat">
              Are you sure for redeem{" "}
              <strong className="px-1 text-blue-300">{item[0]}</strong> ?
            </p>
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={() => onCancle()}
              className="w-full px-4 py-2 mr-3 font-semibold text-white rounded-full shadow-xl focus:outline-none focus:shadow-outline bg-gradient-to-r from-red-500 to-red-900 hover:opacity-90 font-montserrat"
            >
              Cancel
            </button>
            <button
              onClick={() => onConfirm()}
              className="w-full px-4 py-2 font-semibold text-white rounded-full shadow-xl focus:outline-none focus:shadow-outline bg-gradient-to-r from-green-500 to-green-900 hover:opacity-90 font-montserrat"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      {/* {dialogOnStatus && <DialogStatus setDialogOnStatus={setDialogOnStatus} />} */}
    </div>
  );
}
