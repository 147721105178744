import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import QRScanColor from "../../assets/icons/qr-scanner-color.png";
import Error from "../../assets/logo/error-404.png";
// import QRScanColor from "../../assets/icons/qr-scanner-color.png";
import QRScan from "../../assets/images/scan.png";
import logoShow from "../../assets/logo/logo-six-show.png";
import iconsCompleted from "../../assets/icons/completed.png";
import { adminService } from "../../services/adminServices";
import Lottie from "react-lottie";
import {
  // setOpenScan,
  setOpenFront,
  setOpenBack,
  setTextFrontSeed,
  setTextBackSeed,
  // setIsWhitelist,
  // setTextTicketId,
  setFRedeem,
  setAddress
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
// import { ethers } from "ethers";
import _ from "lodash";
import { State } from "../../store/types";
import { constantsRedeem } from "../../constants";

import DialogStatus from "../../components/Dialog/dialogStatus";
import DialogFacingMode from "../../components/Dialog/dialogFacingMode";
import Scan from "../../components/Scan/scan";
import loading from "../../uikit-dev/animations/loading.json";
// import * as CryptoAes from "../../utils/web3";

const optionLoading = {
  loop: true,
  autoplay: true,
  animationData: loading
};

function Home() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const openScanFront = useSelector(
    (state: State) => state.paperWallet.openScanFront
  );
  const frontSeed = useSelector((state: State) => state.paperWallet.frontSeed);
  const backSeed = useSelector((state: State) => state.paperWallet.backSeed);
  // eslint-disable-next-line
  const openScanBack = useSelector(
    (state: State) => state.paperWallet.openScanBack
  );
  const [dialogFacingMode, setDialogFacingMode] = useState(true);
  const [facingMode, setFacingMode] = useState("");
  const [dialogOnStatus, setDialogOnStatus] = useState(false);
  // eslint-disable-next-line
  const [messgaeStatusCode, setMessgaeStatusCode] = useState("");
  // eslint-disable-next-line
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const getID = params.get("id") || "";
  // const regex = /^[0-9]+$/;
  // const match = regex.test(getID);

  useEffect(() => {
    if (frontSeed !== "" && backSeed !== "") {
      const address = backSeed.split(":");
      dispatch(setAddress(address[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontSeed, backSeed]);

  const handleError = (err: any) => {
    console.error(err);
  };

  const handleScan = (data: any, scan: string) => {
    if (data) {
      if (scan === "front") {
        if (data.text.length > 10) {
          setDialogOnStatus(true);
          setMessgaeStatusCode("Invalid Seed Phrase");
        } else {
          dispatch(setTextFrontSeed(data.text));
          dispatch(setOpenFront(false));
        }
      }
      if (scan === "back") {
        if (data.text.length === 79) {
          dispatch(setTextBackSeed(data.text));
          dispatch(setOpenBack(false));
        } else {
          setDialogOnStatus(true);
          setMessgaeStatusCode("Invalid Seed Phrase");
        }
      }
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    const address = backSeed.split(":");
    await adminService
      .verify(address[0], frontSeed, address[1])
      .then((res) => {
        if (_.get(res, "status") === 200) {
          const data = _.get(res, "data");
          const isValid = _.get(data, "data.isValid");
          const transactionHash = _.get(res, "data.statusCode");
          setStatus(isValid);
          if (isValid) {
            navigate(`/redeem?id=${getID}`);
            setDialogOnStatus(false);
          } else {
            setDialogOnStatus(true);
          }
          setMessgaeStatusCode(
            constantsRedeem.STATUS_CODE[transactionHash].MSG
          );
          setLoading(false);
        } else {
          setMessgaeStatusCode("Failed");
          setDialogOnStatus(true);
          setStatus(false);
        }
      })
      .catch(() => {
        setMessgaeStatusCode("Failed");
        setDialogOnStatus(true);
        setStatus(false);
      });
  };

  const clearState = () => {
    setDialogOnStatus(false);
    dispatch(setFRedeem(true));
    dispatch(setTextFrontSeed(""));
    dispatch(setTextBackSeed(""));
    dispatch(setOpenFront(false));
    dispatch(setOpenBack(false));
    navigate(`?id=${getID}`);
    window.location.reload();
  };

  const setMode = (mode: string) => {
    setFacingMode(mode);
    setDialogFacingMode(false);
  };

  return (
    <div>
      {getID ? (
        <main className={`justify-center pt-6 xs:pt-0`}>
          <div className="flex items-end justify-center">
            <div className="grid justify-around gap-4 p-6 mt-24 bg-white md:gap-6 md:grid-cols-2 md:mt-56 bg-opacity-30 backdrop-blur-md xl:flex rounded-xl w-100">
              <div className="p-5 bg-white shadow-lg xl:max-w-sm rounded-xl w-100 xl:w-100 xl:mb-0">
                <div className="mb-3">
                  <span className="text-lg font-bold text-black font-montserrat">
                    Scan Front Paper
                  </span>
                </div>
                {frontSeed !== "" ? (
                  <div className="flex justify-center p-5 mt-5 rounded-lg w-100">
                    <img src={logoShow} alt="logoShow" width={180} />
                  </div>
                ) : openScanFront ? (
                  <Scan
                    handleError={handleError}
                    handleScan={(v) => handleScan(v, "front")}
                    scan="front"
                    modeFacing={facingMode}
                  />
                ) : (
                  <div className="flex justify-center p-5 mt-5 bg-blue-500 rounded-lg shadow-xl w-100 bg-opacity-30 backdrop-blur-md">
                    <img
                      src={QRScan}
                      alt="QRScan"
                      width={220}
                      className="cursor-pointer"
                      onClick={() => dispatch(setOpenFront(true))}
                    />
                  </div>
                )}
              </div>
              <div className="p-5 bg-white shadow-lg xl:max-w-sm rounded-xl w-100 xl:w-100 xl:mb-0">
                <div className="mb-3">
                  <span className="text-lg font-bold text-black font-montserrat">
                    Scan Back Paper
                  </span>
                </div>
                {backSeed !== "" ? (
                  <div className="px-5 mt-5 text-center rounded-lg w-100">
                    <div className="flex items-center justify-center">
                      <img
                        src={iconsCompleted}
                        alt="iconsCompleted"
                        width={130}
                      />
                    </div>
                    <p className="my-5 text-xl font-bold text-green-700 font-montserrat">
                      Scan Completed
                    </p>
                  </div>
                ) : openScanBack ? (
                  <Scan
                    handleError={handleError}
                    handleScan={(v) => handleScan(v, "back")}
                    scan="back"
                    modeFacing={facingMode}
                  />
                ) : (
                  <div className="flex justify-center p-5 mt-5 bg-blue-500 rounded-lg shadow-xl w-100 bg-opacity-30 backdrop-blur-md">
                    <img
                      src={QRScan}
                      alt="QRScan"
                      width={220}
                      className="cursor-pointer"
                      onClick={() => dispatch(setOpenBack(true))}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              className="flex justify-center w-64 px-4 py-2 my-10 font-semibold text-white rounded-full shadow-xl cursor-pointer focus:outline-none focus:shadow-outline bg-gradient-to-r from-green-400 to-blue-500 hover:opacity-90 font-montserrat"
              type="button"
              disabled={loading || frontSeed === "" || backSeed === ""}
              onClick={() => handleCreate()}
            >
              {loading ? (
                <Lottie
                  options={optionLoading}
                  width="12%"
                  style={{ margin: "unset" }}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>

          {dialogFacingMode && (
            <DialogFacingMode
              setDialogOn={setDialogFacingMode}
              setMode={setMode}
            />
          )}

          {dialogOnStatus && (
            <DialogStatus
              setDialogOnStatus={setDialogOnStatus}
              message={messgaeStatusCode}
              status={status}
              clearState={clearState}
            />
          )}
        </main>
      ) : (
        <div>
          <main className={`h-100 d-flex justify-content-center`}>
            <div className="flex items-center justify-center min-h-screen">
              <div>
                <img
                  src={Error}
                  alt="Error"
                  width={300}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default Home;
