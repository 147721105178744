import React from "react";
// import QrReader from "react-qr-scanner";
import { QrReader } from "react-qr-reader";

// eslint-disable-next-line no-unused-vars
const Scan = ({
  handleError,
  handleScan,
  scan,
  modeFacing
}: {
  handleError: any;
  handleScan: any;
  scan: any;
  modeFacing: any;
}) => {
  return (
    <div>
      {/* <div className="flex items-start justify-end mb-2 ml-1 cursor-pointer">
        <svg
          className="w-6 h-6 text-black hover:text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      </div> */}
      <QrReader
        constraints={{ facingMode: modeFacing }}
        scanDelay={100}
        containerStyle={{ width: "300px" }}
        onResult={handleScan}
      />
      {/* <QrReader
        facingmode={selected}
        delay={100}
        style={{ width: "300px" }}
        onError={handleError}
        onScan={handleScan}
        scan={scan}
      /> */}
    </div>
  );
};

export default Scan;
