import React from "react";
import Header from "../../components/Header/header";

interface Props {
  children?: any;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
};

export default Layout;
