import { configureStore } from "@reduxjs/toolkit";
import paperWalletReducer from "./paperWallet";
import redeemReducer from "./redeem";

export default configureStore({
  reducer: {
    paperWallet: paperWalletReducer,
    redeem: redeemReducer
  }
});
