/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RedeemState } from "../types";

const initialState: RedeemState = {
  itemRedeem: []
};

export const redeemSlice = createSlice({
  name: "Redeem",
  initialState,
  reducers: {
    setItemRedeem: (state: any, action: any) => {
      const { itemRedeem } = action.payload;
      state.itemRedeem = itemRedeem;
    }
  }
});

// Actions
export const { setItemRedeem } = redeemSlice.actions;

export const setEventRedeem = (item: []) => async (dispatch: any) => {
  dispatch(setItemRedeem({ itemRedeem: item }));
};

export default redeemSlice.reducer;
