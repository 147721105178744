import React, { useEffect } from "react";

interface IRefreshContext {
  slow: number;
  fast: number;
}

const defaultState = {
  slow: 0,
  fast: 0
};

interface Props {
  children?: any;
}

const FAST_INTERVAL = 10000;
const SLOW_INTERVAL = 60000;

export const RefreshContext =
  React.createContext<IRefreshContext>(defaultState);

export const RefreshProvider: React.FC<Props> = ({ children }) => {
  const [slow, setSlow] = React.useState(defaultState.slow);
  const [fast, setFast] = React.useState(defaultState.fast);

  useEffect(() => {
    const interval = setInterval(async () => {
      setFast((prev) => prev + 1);
    }, FAST_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      setSlow((prev) => prev + 1);
    }, SLOW_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <RefreshContext.Provider value={{ slow, fast }}>
      {children}
    </RefreshContext.Provider>
  );
};
