import React from "react";

export default function DialogRedeemed({ ...props }) {
  return (
    <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-70">
      <div className="flex items-center justify-center h-screen">
        <div className="flex-col px-10 py-8 mx-3 border-blue-900 shadow-xl border-1 bg-gradient-to-r from-green-600 to-blue-600 rounded-xl w-96">
          <p className="text-lg font-bold text-center text-white font-montserrat">
            You cannot redeem another item because you have already redeemed.
          </p>
          <button
            onClick={() => props.goToScan()}
            className="w-full px-4 py-2 mt-4 font-semibold bg-white rounded-full shadow-xl hover:opacity-90 font-montserrat"
          >
            Scan
          </button>
        </div>
      </div>
    </div>
  );
}
