/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { PaperWalletState } from "../types";

const initialState: PaperWalletState = {
  frontSeed: "",
  backSeed: "",
  ticketId: "",
  isWhitelist: false,
  openScanTicket: false,
  openScanFront: false,
  openScanBack: false,
  fromRedeeem: false,
  ownerAddress: "",
  tokenId: ""
};

export const paperWalletSlice = createSlice({
  name: "PaperWallet",
  initialState,
  reducers: {
    setFrontSeed: (state, action) => {
      const { frontSeed } = action.payload;
      state.frontSeed = frontSeed;
    },
    setBackSeed: (state, action) => {
      const { backSeed } = action.payload;
      state.backSeed = backSeed;
    },
    setTicketId: (state, action) => {
      const { ticketId } = action.payload;
      state.ticketId = ticketId;
    },
    setWhitelist: (state, action) => {
      const { isWhitelist } = action.payload;
      state.isWhitelist = isWhitelist;
    },
    setOpenScanTicket: (state, action) => {
      const { openScanTicket } = action.payload;
      state.openScanTicket = openScanTicket;
    },
    setOpenScanFront: (state, action) => {
      const { openScanFront } = action.payload;
      state.openScanFront = openScanFront;
    },
    setOpenScanBack: (state, action) => {
      const { openScanBack } = action.payload;
      state.openScanBack = openScanBack;
    },
    setFromRedeem: (state, action) => {
      const { fromRedeeem } = action.payload;
      state.fromRedeeem = fromRedeeem;
    },
    setOwnerAddress: (state, action) => {
      const { ownerAddress } = action.payload;
      state.ownerAddress = ownerAddress;
    },
    setTokenId: (state, action) => {
      const { tokenId } = action.payload;
      state.tokenId = tokenId;
    }
  }
});

// Actions
export const {
  setFrontSeed,
  setBackSeed,
  setTicketId,
  setWhitelist,
  setOpenScanTicket,
  setOpenScanFront,
  setOpenScanBack,
  setFromRedeem,
  setOwnerAddress,
  setTokenId
} = paperWalletSlice.actions;

export const setTextFrontSeed = (seed: string) => async (dispatch: any) => {
  dispatch(setFrontSeed({ frontSeed: seed }));
};

export const setTextBackSeed = (seed: string) => async (dispatch: any) => {
  dispatch(setBackSeed({ backSeed: seed }));
};

export const setTextTicketId = (ticket: string) => async (dispatch: any) => {
  dispatch(setTicketId({ ticketId: ticket }));
};

export const setIsWhitelist = (whitelist: boolean) => async (dispatch: any) => {
  dispatch(setWhitelist({ isWhitelist: whitelist }));
};

export const setOpenScan = (open: boolean) => async (dispatch: any) => {
  dispatch(setOpenScanTicket({ openScanTicket: open }));
};

export const setOpenFront = (open: boolean) => async (dispatch: any) => {
  dispatch(setOpenScanFront({ openScanFront: open }));
};

export const setOpenBack = (open: boolean) => async (dispatch: any) => {
  dispatch(setOpenScanBack({ openScanBack: open }));
};

export const setFRedeem = (redeem: boolean) => async (dispatch: any) => {
  dispatch(setFromRedeem({ fromRedeeem: redeem }));
};

export const setAddress = (address: string) => async (dispatch: any) => {
  dispatch(setOwnerAddress({ ownerAddress: address }));
};
export const setTokenOfOwner = (tokenId: string) => async (dispatch: any) => {
  dispatch(setTokenId({ tokenId: tokenId }));
};
export default paperWalletSlice.reducer;
