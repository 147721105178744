import { useState, useEffect } from "react";
import logo from "../../assets/logo/logo.png";

export default function Header() {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const getID = params.get("id") || "";
  // const regex = /^[0-9]+$/;
  // const match = regex.test(getID);
  // const [active, setActive] = useState(false);
  // const handleClick = () => {
  //   setActive(!active);
  // };

  const [animateHeader, setAnimateHeader] = useState(false);
  useEffect(() => {
    const listener = () => {
      if (window.scrollY > 140) {
        setAnimateHeader(true);
      } else setAnimateHeader(false);
    };
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <div
      className={`w-full fixed backdrop-blur-lg z-10 trasition ease-in-out duration-500 flex items-center justify-between py-3 px-8 xl:px-16 ${
        animateHeader && "shadow-xl"
      }`}
    >
      <img src={logo} alt="logo" width={45} />
      {getID && (
        <div className="flex w-full">
          <div className="flex flex-col items-end w-full">
            <ul className="lg:flex">
              <li className="py-3 lg:py-0">
                <a
                  href="/redeem"
                  className="md:px-0 lg:px-6 py-4 text-md border-b-4 border-transparent hover:border-indigo-600 focus:border-indigo-600 focus:text-indigo-600 leading-[22px] hover:text-gray-200 text-gray-200 font-montserrat font-semibold"
                >
                  Redeem
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
