import success from "../../assets/icons/completed.png";
import failed from "../../assets/icons//failed.png";

export default function dialogStatus({ ...props }) {
  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
      <div className="flex items-center justify-center h-screen">
        <div className="flex-col px-10 py-6 mx-3 bg-white shadow-xl rounded-xl w-96">
          <div className="flex justify-end cursor-pointer">
            <button
              className="text-2xl text-gray-500"
              onClick={() => props.clearState()}
            >
              x
            </button>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center ">
              <img
                src={props.status ? success : failed}
                alt="success"
                width={150}
                height={150}
              />
            </div>
            <p
              className={`${
                props.status ? "text-green-700" : "text-red-700"
              } font-montserrat font-bold text-3xl mt-4`}
            >
              {props.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
