import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import Layout from "./components/Layout/layout";
import { RefreshProvider } from "./contexts/RefreshContext";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <RefreshProvider>
      <Layout>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Layout>
    </RefreshProvider>
  </Provider>
</React.StrictMode>
);

reportWebVitals();

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <RefreshProvider>
//         <Layout>
//           <BrowserRouter>
//             <App />
//           </BrowserRouter>
//         </Layout>
//       </RefreshProvider>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
