import React from "react";

export default function dialogFacingMode({ ...props }) {
  return (
    <div className="fixed inset-0 z-50 bg-white bg-opacity-40">
      <div className="flex items-center justify-center h-screen">
        <div className="flex-col px-5 py-6 mx-3 bg-white border-2 border-blue-900 shadow-xl rounded-xl w-96">
          <p className="mb-5 text-lg font-bold text-black font-montserrat">
            Select Mode
          </p>
          <div className="flex items-center justify-center">
            <button
              onClick={() => props.setMode("user")}
              className="w-full px-4 py-2 mr-3 font-semibold text-white rounded-full shadow-xl focus:outline-none focus:shadow-outline bg-gradient-to-r from-blue-500 to-gray-900 hover:opacity-90 font-montserrat"
            >
              Front Camera
            </button>
            <button
              onClick={() => props.setMode("environment")}
              className="w-full px-4 py-2 font-semibold text-white rounded-full shadow-xl focus:outline-none focus:shadow-outline bg-gradient-to-r from-blue-500 to-gray-900 hover:opacity-90 font-montserrat"
            >
              Back Camera
            </button>
          </div>
        </div>
      </div>
      {/* {dialogOnStatus && <DialogStatus setDialogOnStatus={setDialogOnStatus} />} */}
    </div>
  );
}
